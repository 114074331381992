import { useRef, useState } from "react";
import "./App.css";
import KakaoMap from "./components/kakao-map";
import Marker from "./components/kakao-map/marker";
import useDistrict from "./hooks/useDistrict";
import { CustomOverlayMap } from "react-kakao-maps-sdk";
import noImage from "./no_image.jpeg";

type MapCenter = {
  lat: number;
  lng: number;
};

function App() {
  const initialCenter = useRef<MapCenter>();
  const { loading, districtData } = useDistrict();
  const [center, setCenter] = useState<MapCenter>({
    lat: 37.6229949188778,
    lng: 127.028140057928,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState([] as any[]);

  const onCreate = (map: kakao.maps.Map) => {
    if (districtData) {
      map.setBounds(districtData.bounds);
      map.setLevel(map.getLevel() + 1);
      map.setCenter(map.getCenter());
      initialCenter.current = {
        lat: map.getCenter().getLat(),
        lng: map.getCenter().getLng(),
      };
    }
  };

  const onClickMarker = (position: MapCenter, index: number) => {
    if (districtData) {
      setCenter(position);
      setSelected(districtData.data[index]);
      setIsOpen(true);
    }
  };

  const onCloseClick = () => {
    setIsOpen(false);
    if (initialCenter.current) {
      setCenter(initialCenter.current);
    }
  };

  if (loading) return <div></div>;

  if (districtData === null)
    return <div>페이지에 문제가 생겼습니다. 관리자에게 문의 해주세요.</div>;

  return (
    <div className="App">
      <KakaoMap center={center} onCreate={onCreate}>
        {districtData.data.map(
          (
            [name, lat, lng, homePage, image, roadAddress, address, postNumber],
            i
          ) => (
            <Marker
              key={name}
              name={name}
              lat={lat}
              lng={lng}
              onClick={onClickMarker}
              index={i}
              image={image}
            />
          )
        )}

        {isOpen && (
          <CustomOverlayMap position={center}>
            <div className="wrap">
              <div className="info">
                <div className="title">
                  {selected[0]}
                  <div
                    className="close"
                    onClick={onCloseClick}
                    title="닫기"
                  ></div>
                </div>
                <div className="body">
                  <div className="img">
                    <img
                      src={selected[4].length ? selected[4] : noImage}
                      width="70"
                      alt={selected[0]}
                    />
                  </div>
                  <div className="desc">
                    <div className="ellipsis">{selected[5]}</div>
                    {selected[3].length !== 0 && (
                      <div>
                        <a
                          href={selected[3]}
                          target="_blank"
                          className="link"
                          rel="noreferrer"
                        >
                          홈페이지
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </CustomOverlayMap>
        )}
      </KakaoMap>
    </div>
  );
}

export default App;
