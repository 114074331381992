import { CustomOverlayMap, MapMarker } from "react-kakao-maps-sdk";
const Marker = ({ name, lat, lng, onClick, image, index }: any) => {
  const handleOnClick = () => {
    onClick({ lat, lng }, index);
  };
  return (
    <>
      {image ? (
        <CustomOverlayMap position={{ lat, lng }}>
          <img
            className="marker-image"
            src={image}
            alt={name}
            onClick={handleOnClick}
          />
        </CustomOverlayMap>
      ) : (
        <MapMarker
          position={{ lat, lng }}
          onClick={handleOnClick}
          key={name + lat + lng}
        />
      )}
    </>
  );
};

export default Marker;
