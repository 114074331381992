import axios from "axios";
import { useEffect, useMemo, useState } from "react";

const sheetKey = process.env.REACT_APP_SHEET_KEY;
const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const pageName = process.env.REACT_APP_PAGE_NAME;

const districtList = ["강북구", "노원구", "도봉구", "성북구", "중랑구"];

const getBoundsData = (points: any[]) => {
  const bounds = new kakao.maps.LatLngBounds();
  points.forEach(([name, lat, lng, ...rest]) =>
    bounds.extend(new kakao.maps.LatLng(lat, lng))
  );
  return bounds;
};

const useDistrict = () => {
  const [loading, setLoading] = useState<Boolean>();
  const [district, setDistrict] = useState("강북구");
  const [error, setError] = useState<any>();
  const [sheetData, setSheetData] = useState<any[]>();

  const getMakerData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${sheetKey}/values/${pageName}?key=${googleApiKey}`
      );
      if (response) {
        setSheetData(response.data.values);
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  // places 데이터를 가공하여 제공
  const districtData: { data: any[]; bounds: any } | null = useMemo(() => {
    if (sheetData) {
      const [header, ...places] = sheetData;

      const tmp = [...places];

      const filteredData = tmp.filter(([a, b, c, d, e, f]) =>
        f.includes("강북구")
      );
      if (filteredData.length) {
        const bounds = getBoundsData(filteredData);
        return { data: tmp, bounds };
      }
    }
    return null;
  }, [sheetData]);

  useEffect(() => {
    getMakerData();
  }, []);

  return { loading, error, districtData };
};
export default useDistrict;
