import { Map } from "react-kakao-maps-sdk";
import { KakaoMapProps } from "./types";

const KakaoMap = ({
  center = { lat: 37.6229949188778, lng: 127.028140057928 },
  style = { width: "100%" },
  children,
  ...rest
}: KakaoMapProps) => {
  return (
    <Map className="custom-kakao-map" center={center} style={style} {...rest}>
      {children}
    </Map>
  );
};

export default KakaoMap;
